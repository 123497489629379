import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sponsors`}</h1>
    <p>{`Type Brigade is Vancouver’s typography meetup. We’ve been getting together since 2011, and now have well over 1,500 talented and curious designers, artists, developers, and type fans, ready to meet and learn about typography, design, and anything connected to it.`}</p>
    <p>{`The current organising team has been running speaker series events and workshops since 2014.`}</p>
    <h2>{`Open Sponsorship`}</h2>
    <p>{`If you are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hiring`}</strong>{` Interested in finding designers in Vancouver to hire`}</li>
      <li parentName="ul"><strong parentName="li">{`Sharing`}</strong>{` Building a product for people who use design and media tools`}</li>
      <li parentName="ul"><strong parentName="li">{`Introducing`}</strong>{` A studio or agency looking to become more well known in Vancouver, and want to help growing the design community here`}</li>
    </ul>
    <p>{`…sponsoring Type Brigade is an easy and inexpensive way to help while supporting a well-received local event. Send us an email using the following button, or email `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`kenneth@typebrigade.com`}</a></p>
    <a className="Button Button--small" href="mailto:kenneth@typebrigade.com?subject=Sponsorship">I’m interested in sponsoring</a>
    <h2>{`Sponsor perks`}</h2>
    <p>{`Sponsorships for our typical speaker events include:`}</p>
    <ul>
      <li parentName="ul">{`The host will give you shout-outs during the meetup while your link and logo or supplied slide is on the screen (once at the beginning, and once at the end)`}</li>
      <li parentName="ul">{`Link and logo on the Type Brigade website`}</li>
      <li parentName="ul">{`We can hold some reserved spots to the meetup for you or members of your team`}</li>
      <li parentName="ul">{`You can bring swag to give out when we sign people in and during the meetup, ex. promotional post card`}</li>
    </ul>
    <p>{`If you have questions or are interested in sponsoring this or a future even, please `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`send Kenneth an email`}</a>{`!`}</p>
    <p>{`Past sponsors Type Brigade have included comapnies and teams like `}<a parentName="p" {...{
        "href": "https://microsoft.ca/"
      }}>{`Microsoft`}</a>{`, `}<a parentName="p" {...{
        "href": "http://metalab.co"
      }}>{`MetaLab`}</a>{`, `}<a parentName="p" {...{
        "href": "http://hootsuite.com"
      }}>{`Hootsuite`}</a>{`, `}<a parentName="p" {...{
        "href": "http://codecore.ca"
      }}>{`CodeCore`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://brainstation.io/"
      }}>{`BrainStation`}</a>{`._`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      